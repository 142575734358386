<template>
  <div class="containerLabsPage pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Категории услуг
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-checkbox
            v-model="isActive"
            size="sm"
            @input="fetchTags()"
          >
            <span class="text-secondary">
              Активирована
            </span>
          </b-form-checkbox>
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openAddTagModal"
          >
            Добавить Категорию
          </b-button>
        </div>
        <b-overlay
          v-if="!partnerListStore || Object.keys(partnerListStore).length === 0"
          :show="isLoading"
          variant="transparent"
          no-wrap
        />
        <div v-if="partnerListStore">
          <div v-if="Object.keys(partnerListStore).length !== 0">
            <b-table
              v-if="partnerListStore"
              :fields="fields"
              :items="partnerListStore.data"
              :busy="isLoading"
              :per-page="0"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              :small="true"
            >
              <template v-slot:cell(isActive)="row">
                <b-badge
                  v-if="row.item.isActive"
                  class="p-2"
                  pill
                  variant="success"
                >
                  Активирован
                </b-badge>
                <b-badge
                  v-else
                  class="p-2"
                  pill
                  variant="danger"
                >
                  Не активирован
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  class="contact-legal-delete mr-2"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Редактировать"
                  @click="openEditPartnerModal(row)"
                >
                  <b-icon
                    icon="pencil-fill"
                  />
                </b-button>
                <b-button
                  v-if="row.item.isActive"
                  v-b-tooltip.hover
                  class="contact-legal-delete mr-2"
                  variant="danger"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Деактивировать"
                  @click="onClickDeActivateTag(row)"
                >
                  <b-icon icon="person-x-fill" />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  class="contact-legal-delete mr-2"
                  variant="success"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Активировать"
                  @click="onClickActivateTag(row)"
                >
                  <b-icon icon="person-check-fill" />
                </b-button>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="partnerListStore"
                class="d-flex align-items-center"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${this.perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="+allCount"
                  :per-page="perPage"
                  style="padding-top: 16px"
                  @input="fetchTags"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>

export default {
  name: 'LaboratoryTags',
  page: {
    title: 'CRM Doctis - Категории услуг',
  },
  components: {},
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Название категории',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'Статус категории',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      isActive: true,
      isLoading: false,
      userData: null,
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      partnerList: [],
      // isActive: false,
      allCount: null,
      // offSet: 0,
      limit: 10,
    };
  },
  computed: {
    partnerListStore() {
      return this.$store.state.Laboratories.partnerList;
    },
    paginationParams() {
      return {
        limit: this.limit,
        offSet: this.offSet,
        isActive: this.isActive,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  async created() {
    await this.fetchTags();
    this.tableSizeHandler(25);
  },
  beforeDestroy() {
    this.$store.commit(this.$types.PARTNERS_SET, null);
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.fetchTags();
    },
    async fetchTags() {
      this.isLoading = true;
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, this.paginationParams);
      this.allCount = data.xTotalCount;
      this.isLoading = false;
    },
    async onClickActivateTag(row) {
      await this.$store.dispatch(this.$types.LABORATORY_TAG_ACTIVATE, row.item.id);
      await this.fetchTags();
    },
    async onClickDeActivateTag(row) {
      await this.$store.dispatch(this.$types.LABORATORY_TAG_DEACTIVATE, row.item.id);
      await this.fetchTags();
    },
    openAddTagModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AddTagModal',
        props: {
          params: this.paginationParams,
        },
      });
    },
    openEditPartnerModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditTagModal',
        props: {
          tagId: row.item.id,
          params: this.paginationParams,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

.crm-table {
  overflow: hidden;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
